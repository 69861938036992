import {ViewportScroller} from '@angular/common';
import {Component} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    public constructor(router: Router, viewportScroller: ViewportScroller) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
}
