import {Routes} from '@angular/router';
import {authenticatedGuard, notAuthenticatedGuard} from './core/guards';

export const routes: Routes = [
    {
        path: 'sign-in',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/sign-in/sign-in.component').then(c => c.SignInComponent),
    },
    {
        path: '',
        canActivate: [authenticatedGuard],
        loadChildren: () => import('./pages/dashboard/dashboard.routes').then(r => r.routes),
    },
] as const;
