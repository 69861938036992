async function getVersion(): Promise<string> {
    return fetch('/version', {cache: 'no-store'})
        .then(
            res => res.text(),
            () => new Promise(resolve => setTimeout(() => resolve(getVersion()), 3000)),
        );
}

getVersion()
    .then(version => {
        setInterval(
            async () => {
                const newVersion = await getVersion();
                if (version != newVersion) {
                    document.location.reload();
                }
            },
            60000,
        );
    });
