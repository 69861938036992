import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {environment} from '../../../environments';
import {catchError, switchMap, throwError} from 'rxjs';

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
    let url = req.url;
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return next(req);
    }
    if (!url.startsWith('/')) {
        url = `/${url}`;
    }
    if (url.startsWith('/token')) {
        return next(req.clone({url: `${environment.apiBase}${url}`}));
    }
    const authenticationService = inject(AuthenticationService);
    let headers = req.headers;
    const authentication = authenticationService.authentication;
    if (authentication) {
        headers = headers.append('Authorization', `Bearer ${authentication.token}`);
    }
    const cloned = req.clone({url: `${environment.apiBase}${url}`, headers});
    return next(cloned).pipe(catchError(error => {
        if (!authentication || !authenticationService.authenticated) {
            return throwError(() => error);
        }
        if (!(error instanceof HttpErrorResponse) || error.status !== 401) {
            return throwError(() => error);
        }
        return authenticationService.refresh()
            .pipe(
                switchMap(
                    (auth) => next(req.clone({
                        url: `${environment.apiBase}${url}`,
                        headers: headers.set('Authorization', `Bearer ${auth.token}`),
                    })),
                ),
            );
    }));
};
